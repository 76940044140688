header.main {
    display: flex;
    padding-top: 25px;
    padding-bottom: 25px;
    background-color: #d1d1d1f0;

    .inner {
        display: flex;
        justify-content: space-between;
        flex-flow:wrap;
        @media(max-width: 768px){
            justify-content: center;
        }        
        .logo {

        }
        .links {
            display: flex;
            justify-content: space-between;
            flex-flow:wrap;              
            .link {
                a{
                    color: #231f20;
                    text-decoration: none;
                }                  
                padding: 15px;   
                @media(max-width: 635px) {
                    padding-top: 15px;
                    padding-bottom: 0px;
                }             
            }                                  
        }
    }

 
}

section.base {
    position: relative;
    padding: 60px 0;
    .section-bar {
        position: absolute;
        top: -65px;
    }
    header {
        text-align: center;
        padding-bottom: 30px;         
        h2 {
            font-size: 32px;
            font-weight: 300;
            margin-bottom: 20px;
            padding-bottom: 0;
            color: #555;            
        }        
    }
}

section.hero {
    width: 100%;
    height: 75vh;
    background: url("../img/studio-hero.jpg") top center;
    background-size: cover;
    position: relative;
    &::before {
        content: "";
        background: rgba(66, 75, 74, 0.88);
        position: absolute;
        bottom: 0;
        top: 0;
        left: 0;
        right: 0;
    }
    .hero-container {
        position:absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        text-align: center;
        position: absolute;
        bottom: 0;
        top: 0;
        left: 0;
        right: 0;
        color: white;
        h1 {
            margin: 0 0 10px 0;
            font-size: 48px;
            font-weight: 300;
            line-height: 56px;
            color: #fff;            
        } 
        h2 {
            color: #eee;
            margin-top: 10px;
            margin-bottom: 25px;
            font-size: 20px;
            font-weight: 300;
            max-width: 650px;           
        }
        .about-sect-btn {
            font-family: "Lato", sans-serif;
            text-decoration: none;
            font-weight: 400;
            font-size: 16px;
            letter-spacing: 1px;
            display: inline-block;
            padding: 8px 28px 10px 28px;
            border-radius: 5px;
            transition: 0.5s;
            border: 1px solid #fff;
            color: #fff;
            &:hover {
                background: #fff;
                color: rgba(66, 75, 74);
                border: 1px solid rgba(66, 75, 74);                
            }
        }              
    }
}

section.about {
    header  {
        text-align: center;
        padding-bottom: 30px; 
        h2 {
            font-size: 32px;
            font-weight: 300;
            margin-bottom: 20px;
            padding-bottom: 0;
            color: #555;            
        }
    }

    .about-img-box {
        img {
            border-radius: 2px;
            box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.1);
        }
    }

    ul {
        list-style: none;
        li {
            padding-bottom: 10px;
            i {
                font-size: 22px;
                padding-right: 15px;
                color: rgba(66, 75, 74);
                line-height: 0;                
            }
        }
    }

    li {

    }
}

section.services {
    // background-color: #f2f9f8;
    background-color: #d1d1d1f0;
    .service-box {
        text-align: center;
        margin-bottom: 35px;
        .icon-box {
            display: flex;
            justify-content: center;
            margin-bottom: 15px;
            i {
                color: #231f20;
                font-size: 42px;
            }
        }
        .service-title {
            font-weight: 700;
            margin-bottom: 15px;
            font-size: 18px;
            text-transform: uppercase;            
        }
        .service-description {
            line-height: 24px;
            font-size: 14px;            
        }
    }
}

section.bar-one {
    text-align: center;
    background: linear-gradient(rgba(1, 41, 42, 0.5), rgba(7, 56, 57, 0.5)), url("../img/studio-one.jpg") fixed center center;
    background-size: cover;
    padding: 60px 0;   
    h3 {
        color: #fff;
        font-size: 28px;
        font-weight: 700;       
    }
    p {
        color: #fff;
    }
}

section.tracks {
    .track-container {
        text-align: center;
        box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.1);
        padding: 30px 20px;
        background: #fff;
    }
}

section.contact {
    background-color: #d1d1d1f0;
    header {

    }
    .contact-about {
        font-size: 36px;
        margin: 0 0 10px 0;
        padding: 0;
        line-height: 1;
        font-weight: 500;
        letter-spacing: 1px;
        color: #231f20; 
        p {
            font-size: 14px;
            line-height: 24px;
            font-family: "Lato", sans-serif;
            color: #231f20;
        }       
    }

    .form-container {
        box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.1);
        margin: auto;
        max-width: 850px;
        .inner {
            background: #fff;

        }
        .response-box {
            text-align: center;
            font-size: 25px;
            padding-top: 263.5px;
            padding-bottom: 259px;
            p {
                margin:0;
            }
        }
        form {
            width: 100%;
            border-radius: 2px;
            padding: 30px 20px;
            background: #fff;
            box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.12);            
        }

        .field {
            margin-bottom: 20px;
        }

        .submit-btn {
            border-color: rgba(209, 209, 209);
            &:hover {
                background-color: rgba(209, 209, 209);

            }
        }

        
    }
}

footer {
    text-align: center;
    padding: 12px;
}